*{ box-sizing: border-box; margin: 0; padding: 0; border: none; outline: none; }
body{ font-family: 'Open Sans', sans-serif; }
h2{ font-size: 42px; font-weight: 700; color: #070606; }
h3{ font-size: 35px; font-weight: 700; color: #070606; }
h4{ font-size: 28px; font-weight: 600; color: #070606; }
p{ font-size: 16px; font-weight: 400; color: #070606; line-height: 24px; }
::-webkit-scrollbar { width: 6px; }
::-webkit-scrollbar-track { background-color: #ddd; }
::-webkit-scrollbar-thumb { background: #e11c39c6; }
::-webkit-scrollbar-thumb:hover { background: #E11C3B; }
label{ margin-bottom: 5px; font-weight: 600; font-size: 18px; }
.section_padding{ padding: 8rem 0; background-color: #000 !important; }
.custom_btn{ background-color: #E11C3B; border: 1px solid #E11C3B; color: #fff; text-decoration: none; font-size: 16px; line-height: 20px; letter-spacing: 1px; font-weight: 600; padding: 10px 20px; border-radius: 15px; transition: 0.2s ease-out; font-family: 'Nunito', sans-serif; }
.custom_btn:hover{ filter: brightness(130%); }
/* #E5E5E5 */
.shadow_heading{ position: relative; z-index: 1; }
.shadow_heading::after { content: ""; height: 100px; width: 500px; position: absolute; top: -20px; left: 50%; z-index: -1; transform: translateX(-50%); background: url(../src/images/section_header_bg.png); background-repeat: no-repeat; background-position: center; background-size: 100%; }
.breadcrumb_div{ margin-bottom: -2rem; position: relative; top: -70px; }
.breadcrumb_div > .container{ display: block !important; }
.swiper, swiper-container{ position: static !important;}
.swiper.swiper-initialized.swiper-backface-hidden{ width: 100%; }
.scroll-to-top{ height: 45px !important; width: 45px !important; border-radius: 30px !important; box-shadow: 0 0 25px 0 rgb(255 255 255 / 30%) !important; border: 2px solid #aaa !important; background-color: #000 !important; display: grid !important; place-items: center !important; }
.scroll-to-top svg{ display: block; height: 20px !important; width: 20px !important; fill: #ccc !important; }




/* Navbar */
#navbar { background-color: #070606; padding: 12px; position: sticky; top: 0; left: 0; z-index: 999; }
#navbar .container{ display: flex; align-items: center; justify-content: space-between; }
#navbar .logo img{ width: 100px; }
#navbar .menu_list{ display: flex; align-items: center; gap: 30px; }
#navbar .menu_list a{ text-decoration: none; color: #fff; font-size: 14px; line-height: 20px; letter-spacing: 1px; font-weight: 600; text-transform: uppercase; border-bottom: 2px solid transparent; }
#navbar .menu_list a.active{ border-bottom: 2px solid #F14A16; }
#navbar .menu_right{ display: flex; align-items: center; gap: 25px; }
#navbar .menu_right .nav_icon{ text-decoration: none; color: #fff; font-size: 24px; cursor: pointer; height: 40px; width: 40px; padding: 8px; transition: 0.2s linear; }
#navbar .menu_right .nav_icon:hover{ background-color: #202020; border-radius: 5px; }
#navbar .menu_right .custom_btn{ color: #E11C3B; border: 1px solid #E11C3B; background-color: transparent; }
#navbar .menu_right .nav_search_div{ position: relative; }
#navbar .menu_right .nav_search_div .nav_search{ opacity: 0; pointer-events: none; position: absolute; bottom: -65px; right: 0; background-color: #000; width: 300px; padding: 10px 15px; border-radius: 5px; border: 2px solid #585858; translate: 0 -15px; transition: 0.2s ease-out; }
#navbar .menu_right .nav_search_div .nav_search.visible{ opacity: 1; pointer-events: auto; translate: 0 0; }
#navbar .menu_right .nav_search_div .nav_search::before { content: ""; height: 10px; width: 10px; transform: rotate(45deg); background-color: #000; position: absolute; top: -7px; right: 15px; border-top: 2px solid #585858; border-left: 2px solid #585858; }
#navbar .menu_right .nav_search_div .nav_search input{ width: 100%; background-color: #000; color: #fff; font-size: 15px; }
#navbar .menu_right .nav_search_div .nav_search input::-webkit-search-cancel-button{ color: #fff; font-size: 15px; }

.menu_right_mobile{ display: none !important; }
.menu_right_mobile .menu_sidebar{ transform: translateX(100%); transition: transform 0.2s linear; position: fixed; top: 0; bottom: 0; right: 0; width: 35%; background-color: #222222; padding: 20px; }
body:has(.menu_sidebar.visible){ overflow: hidden; pointer-events: none; user-select: none; }
.menu_right_mobile .menu_sidebar.visible{ transform: translateX(0); box-shadow: 0 0 0 1000px #0000007a; pointer-events: auto; user-select: auto; }
.menu_right_mobile .menu_sidebar .sidebar_header{ display: flex; justify-content: flex-end; align-items: center; margin-bottom: 20px; }
.menu_right_mobile .menu_sidebar .nav_mobile_search{ background-color: #333333; width: 100%; padding: 10px; border-radius: 5px; display: flex; gap: 10px; align-items: center; }
.menu_right_mobile .menu_sidebar .nav_mobile_search input{ width: 100%; background-color: #333; color: #fff; }
.menu_right_mobile .menu_sidebar .nav_mobile_search .nav_icon{ padding: 0 !important; display: block; height: unset !important; width: unset !important; }
.menu_right_mobile .menu_sidebar .menu_list_mobile{ display: flex; flex-direction: column; margin-top: 20px; }
.menu_right_mobile .menu_sidebar .menu_list_mobile a{ text-decoration: none; color: #fff; font-weight: 600; font-size: 17px; padding: 10px 0; }
.menu_right_mobile .menu_sidebar .menu_list_mobile a.active{ color: #F14A16; }




/* Footer */
.footer{ background-color: #252525; padding: 7rem 0 2rem 0; color: #fff; font-family: 'Poppins', sans-serif;  }
.footer .footer_columns{ display: flex; gap: 20px; justify-content: space-between; }
.footer .footer_columns a{  text-decoration: none; color: #fff; }
.footer .footer_columns > div{ width: 24%; }
.footer .footer_columns > div .footer_socials{ display: flex; gap: 15px; align-items: center; }
.footer p{ margin-bottom: 5px; color: #fff; font-weight: 300; }
.footer .mailing{ text-align: center; margin-top: 60px; }
.footer .mailing label{ margin-bottom: 8px; text-align: left; margin-left: -250px; }
.footer .mailing .input_container{ display: flex; gap: 15px; align-items: center; justify-content: center; }
.footer .mailing input{ padding: 10px 20px; border: 1px solid #E11C3B; border-radius: 15px; background-color: transparent; color: #fff; width: 300px; }
.footer .mailing input::placeholder{ color: #FFFFFF80; }
.footer .copyrights{ text-align: center; margin-top: 55px; }
.footer .licence{ width: 70%; margin: 0 auto; margin-top: 40px; }
.footer .licence p{ text-align: center; font-family: 'Open Sans', sans-serif; }




/* Small Components */
/* .page_header_div{ overflow: hidden; height: 300px; background-position: center !important; background-size: cover !important; background-repeat: no-repeat !important; background-color: #140500 !important; } */
.page_header_div{ overflow: hidden; height: 240px; background-position: center !important; background-size: cover !important; background-repeat: no-repeat !important; background-color: #140500 !important; }

.featured_items_container{ display: flex; flex-wrap: wrap; gap: 15px; justify-content: center; }
.featured_items_container .single_item{ overflow: hidden; border-radius: 20px; }
.featured_items_container .single_item img{ flex-grow: 1; }
.featured_items_container .single_item:hover img{ transform: scale(1.3); transform-origin: center; transition: 0.1s linear; }

.products_container{ display: flex; gap: 25px; flex-wrap: wrap; align-items: flex-start; justify-content: center; margin-top: 50px; width: 100%; padding: 0 40px; position: relative; }
.products_container .single_product_card{ width: 100%; user-select: none; }
.common_layout3 .products_container .single_product_card{ width: 31%; margin-bottom: 25px; }
.products_container .product_slider_left_arrow, .products_container .product_slider_right_arrow{ height: 70px; width: 70px; background-color: #ffffff2c; color: #aaa; border: 2px solid; border-radius: 50%; font-size: 35px; display: grid; place-items: center; position: absolute; bottom: 50%; z-index: 9; }
.products_container .product_slider_left_arrow:hover, .products_container .product_slider_right_arrow:hover{ background-color: #E11C3B; color: #fff; }
.products_container .product_slider_left_arrow{ left: -10px; }
.products_container .product_slider_right_arrow{ right: -10px; }
.single_product_card{ display: flex; flex-direction: column; align-items: center; justify-content: center; position: relative; }
.single_product_card img{ width: auto; max-height: 320px;}
.single_product_card label{ margin-bottom: 0; color: #fff; text-align: center; }
.single_product_card label.product_name{ margin-top: 30px; line-height: 25px; }
.single_product_card label.product_price{ margin-top: 5px; }
.single_product_card .link_card{ background-color: #222222d6; padding: 30px; border-radius: 5px; display: flex; flex-direction: column; align-items: center; width: 85%; color: #fff; position: absolute; top: 80px; opacity: 0; pointer-events: none; transition: 0.2s linear; }
.single_product_card .link_card > *{ width: 100%; max-width: 100% !important; text-align: center;  }
.single_product_card .link_card h5{ margin-bottom: 5px; font-weight: 700; }
.single_product_card .link_card p{ margin-bottom: 15px !important; color: #fff; }
.single_product_card .link_card span{ margin-bottom: 25px; }
.single_product_card .link_card .custom_btn{ background-color: transparent; width: max-content; }
.single_product_card .link_card .custom_btn:hover{ background-color: #E11C3B; }
.single_product_card:hover .link_card{ opacity: 1; pointer-events: auto; }




/* Home */
#home #carouselExampleIndicators .carousel-indicators{ gap: 8px; margin-right: 2%; left: unset; margin-bottom: 1.5rem; }
#home #carouselExampleIndicators .carousel-indicators [data-bs-target]{ height: 7px; width: 7px; border-radius: 50%; }
#home #carouselExampleIndicators .carousel-item{ background-color: #000; }

#home .gallery_section{ background: url(../src/images/home_sec1_bg.png); background-repeat: no-repeat; background-position: center; background-size: cover; }
#home .gallery_section .container{ display: flex; flex-direction: column; justify-content: center; align-items: center; color: #fff; }
#home .gallery_section h2{ margin-bottom: 80px; color: #fff; }
#home .gallery_section p{ text-align: center; color: #fff; max-width: 50%; margin-bottom: 30px; }
#home .gallery_section .galleryImages{ display: flex; flex-wrap: wrap; gap: 10px; justify-content: center; }
#home .gallery_section .galleryImages .gal_img{ width: 49%; border-radius: 20px; position: relative; overflow: hidden; }
#home .gallery_section .galleryImages .gal_img img{ width: 100%; height: 100%; }
#home .gallery_section .galleryImages .gal_img h2{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: #fff; text-align: center; }
#home .gallery_section .galleryImages .gal_img:hover img{ transform: scale(1.2); transform-origin: center; transition: 0.1s linear; }
#home .gallery_section .galleryImages .gal_img:hover h2{ color: #A57C00; }

#home .pop_collection .container{ display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 40px; }
#home .pop_collection .container h2{ color: #fff; text-align: center; margin-bottom: 40px; }
#home .pop_collection .container .pop_card{ position: relative; width: 70%; border: 1px solid #fff; border-radius: 20px; overflow: hidden; }
#home .pop_collection .container .pop_card img{ width: 100%; }
#home .pop_collection .container .pop_card .custom_btn{ position: absolute; right: 50%; bottom: 6%; transform: translateX(50%); box-shadow: 0 0 15px #000000; }

#home .our_story{ background: url(./images/section_shadow.svg); background-size: contain; background-position: center; }
#home .our_story .container{ display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 25px; }
#home .our_story .container h2, .our_story .container p{ color: #fff; text-align: center; }
#home .our_story .container p{ font-weight: 300; max-width: 55%; margin-bottom: -50px; }
#home .our_story .container img{ width: 80%; margin-top: -20px }

#home .subscribe_form{ background: url(../src/images/home_sec3_bg.png); background-repeat: no-repeat; background-position: center; background-size: cover; padding: 15rem 0; }
#home .subscribe_form .container{ text-align: center; }
#home .subscribe_form .container .sub_form_card{ position: relative; padding: 10px; border-radius: 25px; background-color: #fff; margin: 0 auto; width: 55%; text-align: left; }
#home .subscribe_form .container .sub_form_card img{ width: 100%; border-radius: 15px; height: 300px; object-fit: cover; }
#home .subscribe_form .container .sub_form_card h3{ color: #fff; position: absolute; top: 32%; left: 10%; margin-bottom: 0; }
#home .subscribe_form .container .sub_form_card h4{ margin: 20px 9% 10px 9%; font-family: 'Nunito', sans-serif; font-family: 'Poppins', sans-serif; }
#home .subscribe_form .container .sub_form_card .input_container{ display: flex; align-items: center; justify-content: space-between; gap: 20px; padding: 0 9%; margin-bottom: 120px; }
#home .subscribe_form .container .sub_form_card .input_container input{ width: 82%; padding: 14px 28px; color: #464646; background-color: #FAFAFA; border: 1px solid #ddd; border-radius: 10px; font-size: 15px; }
#home .subscribe_form .container .sub_form_card .input_container input::placeholder{ color: #ccc; font-size: 15px; }
#home .subscribe_form .container .sub_form_card .input_container .custom_btn{ padding: 14px 25px; font-size: 15px; border-radius: 10px; min-width: 120px; }

.age_verify_modal{ display: grid; place-items: center; height: 100vh; width: 100vw; background-color: #000000bd; position: fixed; top: 0; left: 0; right: 0; z-index: 999; }
.age_verify_modal .my_modal{ display: flex; flex-direction: column; align-items: center; gap: 10px; background: url(./images/modal_shadow.svg); background-position: center; background-size: cover; background-repeat: no-repeat; padding: 100px; border-radius: 25px; background-color: #3b3b3b; width: 50%; }
.age_verify_modal h2, .age_verify_modal p{ color: #fff; text-align: center; }
.age_verify_modal img{ margin-bottom: 15px; width: 120px; }
.age_verify_modal .modal_actions{ margin-top: 15px; display: flex; gap: 50px; justify-content: center; align-items: center; }
.age_verify_modal .modal_actions button{ padding: 8px 35px; }
.age_verify_modal .my_modal .dob_input{ display: flex; gap: 15px; align-items: center; }
.age_verify_modal .my_modal .dob_input input{ padding: 10px; background-color: #fafafa00; border: 1px solid #fff; display: inline-block; width: 33%; color: #fff; }




/* LAYOUT 1: Wines, Whiskys, Non-alcoholic */
.common_layout1 .layout_section{ background-color: #fff !important; }
.common_layout1 .layout_section .container{ display: flex; flex-direction: column; justify-content: center; align-items: center; }
.common_layout1 .layout_section h2{ margin-bottom: 50px; }
.common_layout1 .layout_section p{ text-align: center; max-width: 50%; margin-bottom: 30px; }

/* LAYOUT 2: T&C, Privacy policy, Product Terms, Private Label */
.common_layout2 .section_padding{ background-color: #fff !important; }
.common_layout2 .section_padding .container{ display: flex; flex-direction: column; justify-content: center; align-items: center; }
.common_layout2 .section_padding .container a{ text-decoration: none; margin-right: auto; color: #070606; display: flex; align-items: center; gap: 5px; }
.common_layout2 .section_padding .container a svg{ font-size: 20px; }
.common_layout2 .section_padding h2{ margin-bottom: 60px; }
.common_layout2 .section_padding p{ width: 100%; margin-bottom: 30px; }

/* LAYOUT 3: Dudies */
.common_layout3 .layout_section{ background: radial-gradient(#322009c8, #000); }
.common_layout3 .layout_section .container{ display: flex; flex-direction: column; justify-content: center; align-items: center; }
.common_layout3 .layout_section h2{ margin-bottom: 20px; color: #fff; }
.common_layout3 .layout_section p{ text-align: center; max-width: 50%; margin-bottom: 30px; color: #fff; }
.common_layout3 .breadcrumb_div a{ text-decoration: none; color: #ffffff; }
.common_layout3 .breadcrumb_div .breadcrumb-item::before, .common_layout3 .breadcrumb_div li{ color: #ffffff; }
.common_layout3 .breadcrumb_div .breadcrumb-item.active{ color: #ffffff; font-weight: 600; }

/* LAYOUT 4: Viewed Product */
.product_page .viewed_product{ background-color: #fff !important; }
.product_page .viewed_product .container{ display: flex; align-items: center; justify-content: space-between; }
.product_page .viewed_product .product_info{ width: 48%; }
.product_page .viewed_product .product_info h4{ margin-bottom: 20px; font-weight: 700; }
.product_page .viewed_product .product_info p{ margin-bottom: 5px; width: 80%; }
.product_page .viewed_product .product_info .more_details{ margin-top: 20px; }
.product_page .viewed_product .product_info .more_details p span{ font-weight: 700; }
.product_page .viewed_product .viewed_img{ text-align: center; width: 45%; }
.product_page .viewed_product .viewed_img img{ width: auto; max-height: 380px; max-width: 270px; }
.product_page .viewed_product .viewed_img:hover img{ transform: scale(1.2); }
.product_page .recommended_products{ padding-bottom: 8rem; }
.product_page .recommended_products label{ color: #070606; }
.product_page .recommended_products .single_product_card .link_card{ display: none; }
.product_page .breadcrumb_div a{ text-decoration: none; color: #070606; }
.product_page .breadcrumb_div .breadcrumb-item::before{ color: #070606; }
.product_page .breadcrumb_div .breadcrumb-item.active{ color: #070606; font-weight: 600; }




/* Story */
#story .our_story{ }
#story .our_story .container{ display: flex; flex-direction: column; justify-content: center; align-items: center; }
#story .our_story .container img{ width: 100%; }
#story .our_story h2{ margin-bottom: 20px; color: #fff; }
#story .our_story p{ text-align: center; max-width: 100%; margin-bottom: 30px; }




/* Contact Us */
#contact .contact_us{ background-color: #fff !important; }
#contact .contact_us .container{ display: flex; flex-direction: column; justify-content: center; align-items: center; }
#contact .contact_us h2{ margin-bottom: 20px; }
#contact .contact_us p{ text-align: center; max-width: 100%; }
#contact .contact_us .container .contact_section{ margin-top: 60px; display: flex; align-items: center; justify-content: space-between; }
#contact .contact_us .container .contact_info{ width: 45%; display: flex; flex-direction: column; align-items: flex-start; gap: 40px; }
#contact .contact_us .container .c_info h4{ font-weight: 700; font-family: 'Open Sans', sans-serif; margin-bottom: 15px; }
#contact .contact_us .container .c_info p{ margin-bottom: 10px; text-align: left; }
#contact .contact_us .container .c_info a{ color: #070606; }
#contact .contact_us .container .c_info a.mailID{ text-decoration: none; }
#contact .contact_us .container .contact_form{ width: 45%; }
#contact .contact_us .container .contact_form .input_section{ display: flex; flex-direction: column; margin-bottom: 25px; }
#contact .contact_us .container .contact_form .input_section label{ margin-bottom: 6px; font-size: 13px; }
#contact .contact_us .container .contact_form .input_section input{ width: 100%; background-color: #f2f2f2; font-size: 15px; padding: 8px 15px; }
#contact .contact_us .container .contact_form .input_section textarea{ width: 100%; background-color: #f2f2f2; font-size: 15px; padding: 8px 15px; height: 160px; resize: none; }
#contact .contact_us .container .contact_form button{ width: 100%; background-color: #252525; font-size: 15px; padding: 12px 15px; color: #fff; }
span.imp{ color: #E11C3B; }




@media screen and (max-width: 1200px) {
    #navbar .menu_list{ gap: 20px; }
    #home .pop_collection .container .pop_card img{ width: 100%; }
    #home .subscribe_form .container .sub_form_card, #home .subscribe_form .container .sub_form_card img{ width: 100%; }
    #home .subscribe_form .container .sub_form_card img{ height: 380px; }

    #home .our_story .container p { margin-bottom: -20px; }
    .featured_items_container .single_item{ width: 48%; }
    .featured_items_container .single_item img{ width: 100%; }
    #whiskys .featured_items_container .single_item{ width: 100%; }
    #non_alcoholic .featured_items_container .single_item{ width: 48%; }
    #non_alcoholic .featured_items_container .single_item:first-child{ width: 100%; }
}

@media screen and (max-width: 992px) {
    h2{ font-size: 35px; }
    h3{ font-size: 28px; }
    h4{ font-size: 22px; }
    p{ font-size: 15px; line-height: 22px; }
    label{ font-size: 16px; }
    .section_padding{ padding: 5rem 0; }
    .custom_btn{  padding: 8px 15px; font-size: 14px; border-radius: 10px; }

    #navbar .menu_list{ display: none; }
    #home .our_story .container p { max-width: 80%; margin-bottom: 0px; }
    #home .subscribe_form{ padding: 8rem 0; }
    #home .subscribe_form .container .sub_form_card .input_container .custom_btn{ width: 140px; }
    #home .gallery_section h2{ margin-bottom: 40px; }
    #home .pop_collection .container h2{ margin-bottom: 0; }
    .common_layout1 .layout_section p{ max-width: 80%; }
    .page_header_div{ height: 170px; }
    .footer .mailing input { padding: 8px 15px; font-size: 14px; }
    .age_verify_modal .my_modal{ width: 70%; padding: 60px; }
    .common_layout3 .layout_section p{ max-width: 80%; }
    .breadcrumb_div { margin-bottom: 0rem; position: relative; top: -30px; }
    .common_layout3 .products_container .single_product_card{ width: 45%; }
    .single_product_card img{ max-height: 250px; }
    .single_product_card .link_card{ top: 55px; }
    .product_page .viewed_product .viewed_img img{ width: auto; max-height: 300px; max-width: 250px; }
    .product_page .viewed_product .product_info h5{ font-size: 18px; }

    .menu_right{ display: none !important; }
    .menu_right_mobile{ display: flex !important; }
}

@media screen and (max-width: 767px) {
    h2{ font-size: 28px; }
    h3{ font-size: 22px; }
    h4{ font-size: 18px; }
    p{ font-size: 14px; line-height: 20px; }
    label{ font-size: 15px; }
    .section_padding{ padding: 4rem 0; }
    .custom_btn { padding: 8px 20px; font-size: 13px; }
    #home .pop_collection .container .pop_card{ width: 100%; }
    #home .pop_collection .container .pop_card.card_season .custom_btn { bottom: 6%; }
    #home .our_story .container p { max-width: 100%; }
    #home .subscribe_form .container .sub_form_card .input_container{ margin-bottom: 50px; }
    #home .subscribe_form .container .sub_form_card .input_container input { padding: 8px 15px; }
    #home .subscribe_form .container .sub_form_card .input_container .custom_btn { padding: 10px 15px; }
    #home .subscribe_form .container .sub_form_card{ border-radius: 15px; }
    #home .our_story{ background-size: cover; }
    .footer .footer_columns { flex-wrap: wrap; }
    .footer .footer_columns > div { width: 45%; }
    .footer .footer_columns > div img { width: 30px; }
    .footer .footer_columns > div:first-child img { width: 100px; }
    .footer { padding: 4rem 0 2rem 0; }
    .shadow_heading::after{ width: 280px; }
    .common_layout1 .layout_section p{ max-width: 100%; }
    .featured_items_container { gap: 8px; }
    .featured_items_container .single_item{ width: 49%; }
    .footer .mailing input{ padding: 7px 15px; border-radius: 10px; }
    .common_layout3 .layout_section p{ max-width: 100%; }
    /* .single_product_card img{ width: 100px; } */
    .single_product_card .link_card{ top: 60px; width: 100%; padding: 15px; }
    .single_product_card .link_card span{ margin-bottom: 10px; }
    /* .product_page .viewed_product .container{ padding-top: 5rem; padding-bottom: 5rem; } */
    .product_page .recommended_products { padding-bottom: 5rem; }
    /* .product_page .viewed_product .viewed_img img{ width: 120px; } */
    .product_page .viewed_product .product_info { width: 65%; }
    .product_page .viewed_product .viewed_img{ width: 35%; }
    .product_page .viewed_product .product_info p{ width: 95%; }
    .menu_right_mobile .menu_sidebar{ width: 42%; }
    #home .subscribe_form .container .sub_form_card img{ height: 220px; }
    .common_layout1 .layout_section h2{ margin-bottom: 30px; }
    .product_page .viewed_product .product_info h5{ font-size: 16px; }
}

@media screen and (max-width: 575px){
    #home .our_story .container img { width: 100%; }
    #home .subscribe_form .container .sub_form_card h4{ margin: 0%; margin-top: 20px; margin-bottom: 5px; }
    #home .subscribe_form .container .sub_form_card .input_container{ padding: 0%; gap: 10px; margin-bottom: 25px; }
    #home .gallery_section .galleryImages .gal_img { width: 70%; }
    .page_header_div { height: 120px; }
    #contact .contact_us .container .contact_section { flex-direction: column; }
    #contact .contact_us .container .contact_info, #contact .contact_us .container .contact_form{ width: 100%; margin-top: 40px; }
    #contact .contact_us .container .contact_form{ padding-top: 40px; border-top: 1px solid #ccc; }
    #contact .contact_us .container .contact_section{ margin-top: 0; }
    #contact .contact_us .container .c_info h4{ margin-bottom: 5px; }
    #contact .contact_us .container .contact_info { gap: 15px; }
    #contact .contact_us .container .contact_form .input_section { margin-bottom: 10px; }
    #contact .contact_us .container .contact_form .input_section label{ margin-bottom: 2px; }
    #contact .contact_us .container .contact_form .input_section input { font-size: 14px; padding: 8px 12px; }
    #contact .contact_us .container .c_info p{ margin-bottom: 2px; }
    #contact .contact_us h2{ margin-bottom: 0; }
    .age_verify_modal .my_modal{ width: 90%; padding: 25px; }
    #home #carouselExampleIndicators .carousel-indicators { gap: 4px; margin-right: 2%; margin-bottom: 0.5rem; }
    #home .pop_collection .container .pop_card img{ margin-bottom: 40px; }
    #home .pop_collection .container .pop_card .custom_btn { width: 220px; left: 50%; text-align: center; padding: 8px; transform: translateX(-50%) !important; }
    .single_product_card img{ max-height: 200px; }
    .products_container{ gap: 20px; }
    .menu_right_mobile .menu_sidebar{ width: 50%; }
    .menu_right_mobile .menu_sidebar .menu_list_mobile a{ font-size: 15px; }
    #navbar .menu_right{ gap: 0; }
    .products_container .product_slider_left_arrow, .products_container .product_slider_right_arrow{ height: 55px; width: 55px; }
    .products_container .product_slider_left_arrow{ left: 0px; }
    .products_container .product_slider_right_arrow{ right: 0px; }
    .carousel-control-next-icon, .carousel-control-prev-icon{ height: 1.2rem; width: 1.2rem; }
}

@media screen and (max-width: 480px){
    h2{ font-size: 24px; }
    h3{ font-size: 20px; }
    h4{ font-size: 16px; }
    p{ font-size: 13px; line-height: 18px; }
    label{ font-size: 14px; }

    .gal_img h2{ font-size: 18px; }
    #home .gallery_section .galleryImages .gal_img { width: 100%; }
    .footer .footer_columns { flex-direction: column; }
    .footer .footer_columns > div{ width: 100%; text-align: center; }
    .footer .footer_columns > div .footer_socials { justify-content: center; }
    .featured_items_container .single_item{ width: 100% !important; }
    .footer .licence{ width: 100%; margin-top: 20px; }
    #home .subscribe_form .container .sub_form_card h3{ top: 25%; }
    #home .subscribe_form .container .sub_form_card .input_container{ flex-direction: column; align-items: flex-start; }
    #home .subscribe_form .container .sub_form_card .input_container input{ width: 100%; }
    #home .subscribe_form .container .sub_form_card .input_container .custom_btn{ padding: 8px 20px; width: max-content; }
    .products_container{ flex-direction: column; align-items: center; }
    .common_layout3 .products_container .single_product_card{ width: 100%; margin-bottom: 0; }
    .single_product_card label.product_name{ margin-top: 10px; }
    .products_container { gap: 40px; }
    .single_product_card .link_card{ width: 65%; }
    .product_page .viewed_product .container{ flex-direction: column-reverse; }
    .product_page .viewed_product .product_info{ margin-top: 22px; width: 100%; }
    .product_page .viewed_product .product_info p{ width: 100%; }
    .product_page .viewed_product .product_info h4{ text-align: center; margin-bottom: 10px; }
    .product_page .viewed_product .viewed_img{ width: 100%; text-align: center; }
    .product_page .viewed_product .viewed_img img{ max-height: 200px; }
    .menu_right_mobile .menu_sidebar{ width: 65%; }
    .menu_right_mobile .menu_sidebar .nav_mobile_search{ gap: 5px; }
    .menu_right_mobile .menu_sidebar .nav_mobile_search input{ font-size: 14px; }
    .footer .mailing label{ margin-left: 0; }
}

@media screen and (max-width: 380px){
    .footer .mailing .input_container{ flex-direction: column; }
    .footer .mailing input{ width: 100%; }
    .age_verify_modal .my_modal{ width: 95%; padding: 20px; }
    .age_verify_modal .modal_actions { gap: 20px; }
    #home .subscribe_form .container .sub_form_card h3{ top: 40%; font-size: 20px; }
    #home .pop_collection .container .pop_card .custom_btn { width: 150px; padding: 6px; }
    .single_product_card .link_card{ width: 90%; }
    .menu_right_mobile .menu_sidebar{ width: 75%; padding: 12px; }
}